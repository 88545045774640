
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'

import { ACTION_APP } from '@/store/actions'
import { IApp } from '@/utils/types'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:onDelete'],
})
export default class AppMixin extends Vue {
  get appId() {
    return this.$route.params.app_id
  }

  async onDelete(app: IApp) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const success = await this.$store.dispatch(ACTION_APP.DELETE, app._id)
        if (success) {
          this.$q.notify({
            message: this.$t('messages.deleted'),
            color: 'positive',
          })

          this.goto('home')
          this.$emit('update:onDelete')
        }
      })
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  gotoDetail(app: IApp) {
    this.goto('overview', { app_id: app._id })
  }
}
