<template>
  <CLoading :loading="loading" />
  <q-table
    bordered
    flat
    dense
    title="Treats"
    ref="welcomeList"
    v-if="initials"
    :rows="initials"
    row-key="title"
    :rows-per-page-options="[10]"
    separator="cell"
    :no-data-label="$t('messages.no_data_available')"
    class="text-grey-8"
  >
    <template v-slot:header="props">
      <q-tr>
        <q-th key="action" rowspan="2" class="text-center" style="width: 50px">
          <span class="text-bold">{{ $t('label.prod') }}</span></q-th
        >
        <q-th key="action" rowspan="2" class="text-center" style="width: 50px">
          <span class="text-bold">{{ $t('label.test') }}</span></q-th
        >
        <q-th key="action" rowspan="2" class="text-center" style="width: 100px"></q-th>
        <q-th :props="props" key="title" rowspan="2">
          <span class="text-bold">{{ $t('label.initial.campaign_name') }}</span>
        </q-th>
        <q-th :props="props" key="delivered" rowspan="2">
          <span class="text-bold">{{ $t('label.initial.delivered') }}</span>
        </q-th>

        <q-th :props="props" key="total_user_of_goal" rowspan="2">
          <span class="text-bold">{{ $t('label.total_user_of_goal') }}</span>
        </q-th>
        <q-th :props="props" key="gtr" rowspan="2">
          <span class="text-bold">GTR</span>
        </q-th>
        <q-th :props="props" key="total_click" rowspan="2">
          <span class="text-bold">{{ $t('label.total_click') }}</span>
        </q-th>
        <q-th :props="props" key="success_rate" rowspan="2">
          <span class="text-bold">CVR</span>
        </q-th>
        <q-th :props="props" key="success_count" rowspan="2">
          <span class="text-bold">CV</span>
        </q-th>
      </q-tr>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props" :index="props.rowKey">
        <q-td>
          <q-toggle
            round
            v-model="props.row.is_active"
            val="props.row.is_active"
            @click="handleToggleActive(props.row)"
          />
        </q-td>
        <q-td>
          <q-toggle
            round
            v-model="props.row.is_test_mode"
            val="props.row.is_test_mode"
            @click="handleToggleTest(props.row)"
          />
        </q-td>
        <q-td key="action" :props="props.index">
          <q-btn size="sm" round outline icon="edit" @click="onEdit(props.row)" class="q-mr-sm btn-custom" />
          <q-btn size="sm" round outline color="grey" icon="content_copy" @click="onCopy(props.row)" class="q-mr-sm" />
          <q-btn size="sm" round outline color="red" icon="delete" @click="onDelete(props.row)" class="q-mr-sm" />
        </q-td>
        <q-td key="title" :props="props">
          {{ props.row.title }}<br />
          <span class="cursor-pointer hyper-link-chart" @click="onDetails(props.row)">{{
            $t('label.data_transition')
          }}</span>
        </q-td>
        <q-td key="delivered" :props="props">
          <q-chip color="primary" text-color="white">
            {{ numberWithCommas(props.row.delivered) }}
          </q-chip>
        </q-td>
        <q-td key="date_picker" :props="props">
          {{ toLocalTime(props.row.date_picker) || '-' }}
        </q-td>
        <q-td key="total_user_of_goal" :props="props">
          <q-chip color="primary" text-color="white">
            {{ numberWithCommas(props.row.total_user_of_goal) }}
          </q-chip>
        </q-td>
        <q-td key="gtr" :props="props">
          <q-chip color="primary" text-color="white"> {{ roundNumber(props.row.gtr) }}% </q-chip>
        </q-td>
        <q-td key="total_click" :props="props">
          <q-chip color="primary" text-color="white">
            {{ numberWithCommas(props.row.total_click) }}
          </q-chip>
        </q-td>
        <q-td key="success_rate" :props="props">
          <q-chip color="primary" text-color="white"> {{ roundNumber(props.row.success_rate) }}% </q-chip>
        </q-td>
        <q-td key="success_count" :props="props">
          <q-chip color="primary" text-color="white">
            {{ numberWithCommas(props.row.success_count) }}
          </q-chip>
        </q-td>
      </q-tr>
    </template>

    <template v-slot:top separator>
      <h2 class="text-h6 q-mr-md text-black">{{ $t('label.initial.initial_campaign_list') }}</h2>
      <DatePicker
        :default-date-range="dateRange"
        :disable="false"
        @update:onChangeDateRange="changeDateRange"
      ></DatePicker>
      <q-space />
      <q-select
        outlined
        dense
        clearable
        multiple
        option-value="_id"
        option-label="target_name"
        v-model="selectedTargetIds"
        :options="targetSettings"
        emit-value
        map-options
        class="q-ma-md"
        style="width: 240px"
        :label="$t('label.target_setting.target_setting')"
      />
      <q-btn v-if="scenario_templates.length > 0" no-caps color="primary" :label="$t('add_new')">
        <q-menu>
          <q-list dense style="min-width: 200px">
            <q-item clickable v-close-popup @click="onAdd(0)">
              <q-item-section>{{ $t('create_new') }}</q-item-section>
            </q-item>
            <q-item clickable>
              <q-item-section>{{ $t('from_template') }}</q-item-section>
              <q-menu anchor="top end" self="top start">
                <q-list style="min-width: 200px">
                  <q-item
                    v-for="(template, index) in scenario_templates"
                    :key="index"
                    dense
                    clickable
                    @click="onAdd(template._id)"
                  >
                    <q-item-section>{{ template.title }}</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
      <q-btn v-else no-caps color="primary" @click="onAdd(0)" :label="$t('add_new')" />
    </template>
  </q-table>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IDateRange, IInitialCampaign, IScenarioTemplate, ITargetSetting } from '@/utils/types'
import { Watch } from 'vue-property-decorator'
import { ACTION_INITIAL, ACTION_SCENARIO_TEMPLATE, ACTION_TARGET_SETTING } from '@/store/actions'
import { constant } from '@/utils/constants'
import { Formater } from '@/utils/formater'
import cloneDeep from 'lodash/cloneDeep'
import InitialCampaignMixin from './mixins/InitialCampaignMixin.vue'
import CLoading from '@/components/common/ui/CLoading.vue'
import DatePicker from '@/components/common/ui/DatePicker.vue'
import { lastNMonth, now } from '@/utils/datetime'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'

@Options({
  components: { CLoading, DatePicker },
  directives: { maska },
  emits: [],
})
export default class InitialCampaignList extends mixins(InitialCampaignMixin, BaseFormMixin) {
  initials: IInitialCampaign[] = []
  scenario_templates: IScenarioTemplate[] = []
  loading = true

  dateRange: IDateRange = {
    from: '',
    to: '',
  }

  selectedTargetIds = ['all']

  get initial_lists() {
    return this.initials
  }

  get targetSettings() {
    let result: ITargetSetting[] = []
    const all: ITargetSetting = {
      _id: 'all',
      app_id: this.selectedAppId,
      target_name: this.$t('label.all'),
    }

    result.push(all)
    result = result.concat(this.$store.getters.targetSettings)
    return result
  }

  @Watch('$store.getters.initials')
  initialChanged() {
    const getInitials = cloneDeep(this.$store.getters.initials)
    if (getInitials) {
      return (this.initials = getInitials)
    }
  }

  @Watch('$store.getters.scenarioTemplates')
  scenarioTemplateChanged() {
    const getScenarios = cloneDeep(this.$store.getters.scenarioTemplates)
    if (getScenarios) {
      return (this.scenario_templates = getScenarios)
    }
  }

  @Watch('selectedAppId', { immediate: true })
  async appIdChanged() {
    if (!this.selectedAppId) {
      return
    }

    this.dateRange = {
      from: lastNMonth(1),
      to: now(),
    }
  }

  @Watch('selectedTargetIds')
  handleChangeTargetSetting() {
    if (this.selectedTargetIds === null || this.selectedTargetIds.length === 0) {
      this.selectedTargetIds = ['all']
    }
    this.loadIntials()
  }

  async loadIntials() {
    this.loading = true
    let date_from
    let date_to
    if (this.dateRange) {
      if (this.dateRange.from === undefined) {
        date_from = this.dateRange
        date_to = this.dateRange
      } else {
        date_from = this.dateRange.from
        date_to = this.dateRange.to
      }
    }

    await this.$store.dispatch(ACTION_TARGET_SETTING.LOAD_ITEMS_WITHOUT_DETAIL, {
      app_id: this.selectedAppId,
    })

    await this.$store.dispatch(ACTION_INITIAL.LOAD_ITEMS_SUMMARY, {
      app_id: this.selectedAppId,
      date_from,
      date_to,
      target_setting_ids: this.selectedTargetIds,
    })

    await this.$store.dispatch(ACTION_SCENARIO_TEMPLATE.LOAD_ITEMS_WITHOUT_DETAIL, {
      app_id: this.selectedAppId,
    })

    this.loading = false
  }

  async updateIsActive(item: IInitialCampaign) {
    const isSuccess = await this.$store.dispatch(ACTION_INITIAL.IS_ACTIVE, item)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async updateTestMode(item: IInitialCampaign) {
    const isSuccess = await this.$store.dispatch(ACTION_INITIAL.TEST_MODE, item)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async handleToggleActive(record: IInitialCampaign) {
    const existInitial = this.initial_lists.find((item) => item._id !== record._id && item.is_active === true)
    if (!existInitial) {
      if (!record.is_active) {
        this.updateIsActive(record)
      } else {
        this.$q
          .dialog({
            title: this.$t('messages.confirm'),
            message: this.$t('messages.are_you_sure_to_turn_on_active_for_this_item'),
            cancel: {
              flat: true,
              label: this.$t('messages.no'),
            },
            ok: {
              flat: true,
              label: this.$t('messages.yes'),
            },
            persistent: true,
          })
          .onOk(async () => {
            this.updateIsActive(record)
          })
          .onCancel(async () => {
            record.is_active = false
          })
      }
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.please_diasble_all_before_this_feature_in_enabled'),
          cancel: {
            flat: true,
            label: this.$t('cancel'),
          },
          persistent: true,
        })
        .onOk(async () => {
          existInitial.is_active = false
          this.updateIsActive(existInitial)
        })

      record.is_active = false
    }
  }

  async handleToggleTest(record: IInitialCampaign) {
    const existInitial = this.initial_lists.find((item) => item._id !== record._id && item.is_test_mode === true)
    if (!existInitial) {
      this.updateTestMode(record)
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.please_diasble_all_before_this_feature_in_enabled'),
          cancel: {
            flat: true,
            label: this.$t('cancel'),
          },
          persistent: true,
        })
        .onOk(async () => {
          existInitial.is_test_mode = false
          this.updateTestMode(existInitial)
        })

      record.is_test_mode = false
    }
  }

  onAdd(scenario_template_id) {
    if (scenario_template_id) {
      this.goto('initial_setting', {
        app_id: this.selectedAppId,
        action_type: constant.ACTION_TYPE.ADD,
        scenario_template_id: scenario_template_id,
      })
    } else {
      this.goto('initial_setting', { app_id: this.selectedAppId, action_type: constant.ACTION_TYPE.ADD })
    }
  }

  onEdit(data: IInitialCampaign) {
    this.goto('initial_setting', {
      app_id: this.selectedAppId,
      initial_id: data._id,
      action_type: constant.ACTION_TYPE.EDIT,
    })
  }

  onDetails(data: IInitialCampaign) {
    let date_from
    let date_to
    if (this.dateRange) {
      if (this.dateRange.from === undefined) {
        date_from = this.dateRange
        date_to = this.dateRange
      } else {
        date_from = this.dateRange.from
        date_to = this.dateRange.to
      }
    }
    this.goto('initial_details', {
      app_id: this.selectedAppId,
      campaign_type: 'initial',
      campaign_id: data._id,
      from_date: date_from,
      to_date: date_to,
    })
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  roundNumber(num) {
    return Formater.round(num)
  }

  changeDateRange(value) {
    this.dateRange = value

    this.loadIntials()
  }
}
</script>
<style lang="sass" scoped></style>
