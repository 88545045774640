<template>
  <q-input
    outlined
    dense
    v-model="rangePicker"
    style="min-width: 250px"
    :placeholder="$t('label.audience.select_date_range_picker')"
    :disable="disable"
    readonly
  >
    <template v-slot:append>
      <q-icon name="event" class="cursor-pointer">
        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale" @before-show="updateProxy">
          <q-date v-model="proxyDate" range :mask="maskDateRange" ref="myDatePicker">
            <div class="row items-center justify-end">
              <q-btn label="Cancel" color="primary" flat v-close-popup />
              <q-btn label="OK" color="primary" flat @click="saveDatePicker" v-close-popup />
            </div>
          </q-date>
        </q-popup-proxy>
      </q-icon>
      <q-icon name="close" @click="onClearDateRangeChange()" class="cursor-pointer" v-if="dateRange.from !== ''" />
    </template>
  </q-input>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import AppMixin from '@/components/app/mixins/AppMixin.vue'
import BaseFormMixin from '@/components/common/mixins/BaseFormMixin.vue'
import { constant } from '@/utils/constants'
import { IDateRange } from '@/utils/types'
import { Prop, Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { useQuasar } from 'quasar'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:onChangeDateRange'],
})
export default class DatePicker extends mixins(AppMixin, BaseFormMixin) {
  @Prop()
  defaultDateRange!: IDateRange

  @Prop()
  disable!: boolean

  rangePicker = ''
  date_range = constant.DATE_RANGE
  maskDateRange = constant.DATE_FORMAT
  quasar = useQuasar()

  proxyDate: IDateRange = {
    from: '',
    to: '',
  }

  dateRange: IDateRange = {
    from: '',
    to: '',
  }

  updateProxy() {
    this.proxyDate = this.dateRange
  }

  saveDatePicker() {
    this.dateRange = this.proxyDate
  }

  onClearDateRangeChange() {
    this.dateRange = {
      from: '',
      to: '',
    }
  }

  @Watch('dateRange')
  dateRangeChanged() {
    if (this.dateRange && this.dateRange.from === undefined) {
      this.rangePicker = cloneDeep(this.dateRange) as string
    } else if (this.dateRange && this.dateRange.from !== '') {
      this.rangePicker = this.dateRange.from + ', ' + this.dateRange.to
    } else {
      this.rangePicker = ''
    }

    if (this.dateRange == null) {
      this.dateRange = {
        from: '',
        to: '',
      }
      this.rangePicker = ''
    }

    const check = this.checkRange(this.dateRange.from, this.dateRange.to)
    if (check) {
      this.$emit('update:onChangeDateRange', this.dateRange)
    } else {
      this.rangePicker = ''
      this.dateRange = {
        from: '',
        to: '',
      }
      this.$emit('update:onChangeDateRange', this.dateRange)
    }
  }

  @Watch('defaultDateRange')
  getDefaultDateRange() {
    this.dateRange = this.defaultDateRange
  }

  checkRange(start, end) {
    const from = moment(start, 'YYYY-MM-DD')
    const to = moment(end, 'YYYY-MM-DD')
    const diff = to.diff(from, 'days')
    if (diff > this.date_range) {
      this.quasar.notify({
        type: 'negative',
        message: this.$t('errors.over_92_days'),
      })
      return false
    } else {
      return true
    }
  }

  mounted() {
    this.dateRange = this.defaultDateRange
  }
}
</script>

<style scoped>
</style>
