export const Formater = {
  numberFormat: (num: number) => {
    if (!num) {
      return 0
    }

    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  },
  round: (num: number) => {
    const rounded = Math.pow(10, 1)
    return (Math.round(num * rounded) / rounded).toFixed(1)
  },

  formatStringWithParams: (value: string, ...params) => {
    const matchRegex = value.match(/{([^}]+)}/g) || []
    matchRegex.forEach((item, index) => {
      value = value.replace(item, params[index])
    })

    return value
  },
  padLeadingZeros(num: number, size: number) {
    let s = num + ''
    while (s.length < size) {
      s = '0' + s
    }
    return s
  },
}
