import moment from 'moment-timezone'
import { constant } from './constants'

export const localTime = (dateTime, format = constant.DATETIME_FORMAT) => {
  const result = moment(moment.utc(dateTime)).local()
  if (result.isValid()) {
    return result.format(format)
  }

  return '-'
}

export const convertStrToDate = (dateTime, format = constant.DATETIME_FORMAT) => {
  return moment(dateTime, format)
}

export const last7Days = (format = constant.DATE_FORMAT) => {
  const last7days = moment()
    .subtract(1, 'weeks')
    .toDate()
  last7days.setHours(0)
  last7days.setMinutes(0)
  last7days.setSeconds(0)

  return moment(last7days).format(format)
}

export const lastNMonth = (n: number, format = constant.DATE_FORMAT) => {
  const last = moment()
    .subtract(n, 'months')
    .toDate()
  last.setHours(0)
  last.setMinutes(0)
  last.setSeconds(0)

  return moment(last).format(format)
}

export const now = (format = constant.DATE_FORMAT) => {
  return moment().format(format)
}

export const lastNDays = (date: string, n: number, format = constant.DATE_FORMAT) => {
  const last = moment(date, format)
    .subtract(n, 'days')
    .toDate()
  last.setHours(0)
  last.setMinutes(0)
  last.setSeconds(0)

  return moment(last).format(format)
}

export const nextNDays = (date: string, n: number, format = constant.DATE_FORMAT) => {
  const next = moment(date, format)
    .add(n, 'days')
    .toDate()
  next.setHours(0)
  next.setMinutes(0)
  next.setSeconds(0)

  return moment(next).format(format)
}
