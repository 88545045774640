
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IDateRange, IInitialCampaign, IScenarioTemplate, ITargetSetting } from '@/utils/types'
import { Watch } from 'vue-property-decorator'
import { ACTION_INITIAL, ACTION_SCENARIO_TEMPLATE, ACTION_TARGET_SETTING } from '@/store/actions'
import { constant } from '@/utils/constants'
import { Formater } from '@/utils/formater'
import cloneDeep from 'lodash/cloneDeep'
import InitialCampaignMixin from './mixins/InitialCampaignMixin.vue'
import CLoading from '@/components/common/ui/CLoading.vue'
import DatePicker from '@/components/common/ui/DatePicker.vue'
import { lastNMonth, now } from '@/utils/datetime'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'

@Options({
  components: { CLoading, DatePicker },
  directives: { maska },
  emits: [],
})
export default class InitialCampaignList extends mixins(InitialCampaignMixin, BaseFormMixin) {
  initials: IInitialCampaign[] = []
  scenario_templates: IScenarioTemplate[] = []
  loading = true

  dateRange: IDateRange = {
    from: '',
    to: '',
  }

  selectedTargetIds = ['all']

  get initial_lists() {
    return this.initials
  }

  get targetSettings() {
    let result: ITargetSetting[] = []
    const all: ITargetSetting = {
      _id: 'all',
      app_id: this.selectedAppId,
      target_name: this.$t('label.all'),
    }

    result.push(all)
    result = result.concat(this.$store.getters.targetSettings)
    return result
  }

  @Watch('$store.getters.initials')
  initialChanged() {
    const getInitials = cloneDeep(this.$store.getters.initials)
    if (getInitials) {
      return (this.initials = getInitials)
    }
  }

  @Watch('$store.getters.scenarioTemplates')
  scenarioTemplateChanged() {
    const getScenarios = cloneDeep(this.$store.getters.scenarioTemplates)
    if (getScenarios) {
      return (this.scenario_templates = getScenarios)
    }
  }

  @Watch('selectedAppId', { immediate: true })
  async appIdChanged() {
    if (!this.selectedAppId) {
      return
    }

    this.dateRange = {
      from: lastNMonth(1),
      to: now(),
    }
  }

  @Watch('selectedTargetIds')
  handleChangeTargetSetting() {
    if (this.selectedTargetIds === null || this.selectedTargetIds.length === 0) {
      this.selectedTargetIds = ['all']
    }
    this.loadIntials()
  }

  async loadIntials() {
    this.loading = true
    let date_from
    let date_to
    if (this.dateRange) {
      if (this.dateRange.from === undefined) {
        date_from = this.dateRange
        date_to = this.dateRange
      } else {
        date_from = this.dateRange.from
        date_to = this.dateRange.to
      }
    }

    await this.$store.dispatch(ACTION_TARGET_SETTING.LOAD_ITEMS_WITHOUT_DETAIL, {
      app_id: this.selectedAppId,
    })

    await this.$store.dispatch(ACTION_INITIAL.LOAD_ITEMS_SUMMARY, {
      app_id: this.selectedAppId,
      date_from,
      date_to,
      target_setting_ids: this.selectedTargetIds,
    })

    await this.$store.dispatch(ACTION_SCENARIO_TEMPLATE.LOAD_ITEMS_WITHOUT_DETAIL, {
      app_id: this.selectedAppId,
    })

    this.loading = false
  }

  async updateIsActive(item: IInitialCampaign) {
    const isSuccess = await this.$store.dispatch(ACTION_INITIAL.IS_ACTIVE, item)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async updateTestMode(item: IInitialCampaign) {
    const isSuccess = await this.$store.dispatch(ACTION_INITIAL.TEST_MODE, item)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async handleToggleActive(record: IInitialCampaign) {
    const existInitial = this.initial_lists.find((item) => item._id !== record._id && item.is_active === true)
    if (!existInitial) {
      if (!record.is_active) {
        this.updateIsActive(record)
      } else {
        this.$q
          .dialog({
            title: this.$t('messages.confirm'),
            message: this.$t('messages.are_you_sure_to_turn_on_active_for_this_item'),
            cancel: {
              flat: true,
              label: this.$t('messages.no'),
            },
            ok: {
              flat: true,
              label: this.$t('messages.yes'),
            },
            persistent: true,
          })
          .onOk(async () => {
            this.updateIsActive(record)
          })
          .onCancel(async () => {
            record.is_active = false
          })
      }
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.please_diasble_all_before_this_feature_in_enabled'),
          cancel: {
            flat: true,
            label: this.$t('cancel'),
          },
          persistent: true,
        })
        .onOk(async () => {
          existInitial.is_active = false
          this.updateIsActive(existInitial)
        })

      record.is_active = false
    }
  }

  async handleToggleTest(record: IInitialCampaign) {
    const existInitial = this.initial_lists.find((item) => item._id !== record._id && item.is_test_mode === true)
    if (!existInitial) {
      this.updateTestMode(record)
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.please_diasble_all_before_this_feature_in_enabled'),
          cancel: {
            flat: true,
            label: this.$t('cancel'),
          },
          persistent: true,
        })
        .onOk(async () => {
          existInitial.is_test_mode = false
          this.updateTestMode(existInitial)
        })

      record.is_test_mode = false
    }
  }

  onAdd(scenario_template_id) {
    if (scenario_template_id) {
      this.goto('initial_setting', {
        app_id: this.selectedAppId,
        action_type: constant.ACTION_TYPE.ADD,
        scenario_template_id: scenario_template_id,
      })
    } else {
      this.goto('initial_setting', { app_id: this.selectedAppId, action_type: constant.ACTION_TYPE.ADD })
    }
  }

  onEdit(data: IInitialCampaign) {
    this.goto('initial_setting', {
      app_id: this.selectedAppId,
      initial_id: data._id,
      action_type: constant.ACTION_TYPE.EDIT,
    })
  }

  onDetails(data: IInitialCampaign) {
    let date_from
    let date_to
    if (this.dateRange) {
      if (this.dateRange.from === undefined) {
        date_from = this.dateRange
        date_to = this.dateRange
      } else {
        date_from = this.dateRange.from
        date_to = this.dateRange.to
      }
    }
    this.goto('initial_details', {
      app_id: this.selectedAppId,
      campaign_type: 'initial',
      campaign_id: data._id,
      from_date: date_from,
      to_date: date_to,
    })
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  roundNumber(num) {
    return Formater.round(num)
  }

  changeDateRange(value) {
    this.dateRange = value

    this.loadIntials()
  }
}
