
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IInitialCampaign } from '@/utils/types'
import { ACTION_INITIAL } from '@/store/actions'
import { constant } from '@/utils/constants'

@Options({
  components: {},
  directives: { maska },
})
export default class InitialCampaignMixin extends Vue {
  get selectedAppId() {
    return this.$route.params.app_id
  }

  get selectedCampaignId() {
    return this.$route.params.initial_id || ''
  }

  get actionType() {
    return this.$route.params.action_type || ''
  }

  get selectedScenarioTemplateId() {
    return this.$route.params.scenario_template_id || ''
  }

  async onDelete(initial: IInitialCampaign) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const success = await this.$store.dispatch(ACTION_INITIAL.DELETE, initial._id)
        if (success) {
          this.$q.notify({
            message: this.$t('messages.deleted'),
            color: 'positive',
          })
        }
      })
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  onCopy(data: IInitialCampaign) {
    this.goto('initial_setting', {
      app_id: this.selectedAppId,
      initial_id: data._id,
      action_type: constant.ACTION_TYPE.COPY,
    })
  }
}
